<template>
  <div>version: {{ version }}</div>
</template>

<script lang="ts">
import Vue from 'vue'
import { version } from '../../package.json'

export default Vue.extend({
  data() {
    return {
      version: version,
    }
  },
})
</script>
